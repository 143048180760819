<template>
  <div class="vue-leaflet">
    <div class="plugin">
      <div
        :class="{ pbtn: true, activebtn: 'vector' === currentMapLayer.type }"
        @click="toggleMapType('vector')"
      >
        矢量地图
      </div>
      <div
        :class="{ pbtn: true, activebtn: 'satellite' === currentMapLayer.type }"
        @click="toggleMapType('satellite')"
      >
        影像地图
      </div>
    </div>
    <l-map
      style="width: 100%; height: 700px; z-index: 1"
      :zoom="zoom"
      :center="center"
    >
      <l-tile-layer :url="currentMapLayer.url"></l-tile-layer>
      <l-tile-layer
        :url="currentMapLayer.texturl"
        :attribution="currentMapLayer.attribution"
      ></l-tile-layer>

      <l-polygon
        v-for="polygon in polygonArr"
        :key="polygon.latlngs"
        :name="polygon.name"
        :visible="polygon.visible"
        :lat-lngs="polygon.latlngs"
        :color="polygon.color"
      />

      <l-marker
        v-for="item in pointsList"
        :key="item.id"
        :icon="icon()"
        :lat-lng="[item.lng_lat_gaode.lat, item.lng_lat_gaode.lng]"
        :offset="[8, 0]"
      >
        <l-tooltip
          :options="{
            permanent: true,
            interactive: true,
            offset: [4, -16],
            className: 'color',
          }"
        >
          <div class="tooltipInnerBox">
            <img cLass="icon" :src="item.imgurl" />
            <div class="title">
              {{ item.name }}
            </div>
          </div>
        </l-tooltip>
      </l-marker>
    </l-map>
  </div>
</template>

<script>
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LPolyline,
  LTooltip,
  LPolygon,
  LIcon,
} from "vue2-leaflet";
import {
  vectorMapLayer,
  satelliteMapLayer,
  leafletmapurl,
  leafletmaptexturl,
  leafletmapyunxuanurl,
  isgaode,
} from "@/settings.js";

import { latLng, icon } from "leaflet";
import { polygonData, pointData } from "@/api/cityresearch";

export default {
  props: {
    cate: {
      type: Number,
      default: 1,
    }, //商业 2产业
    polygondata: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },

  name: "VueLeaflet",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LPolyline,
    LTooltip,
    LPolygon,
    LIcon,
  },
  watch: {
    // 'currentArea':{
    //     handler(newVal,oldVal){
    //         this.getCityBoundaryData()
    //         this.getPointData()
    //         // this.getYe()
    //     },
    //     deep:true,
    //     immediate:true
    // }
    polygondata: {
      handler(v) {
        this.getCityBoundaryData();
        this.getPointData();
        // console.log('==data==', v)
        // this.handlerData(v)
        if (v.length === 0) {
          this.polygons = [];
          this.map.removeOverlay(this.blockoverlay);
        } else {
          v.map((item) => {
            this.handlerData(item.set_gaode);
          });
        }
      },
      deep: true,
    },
  },
  computed: {
    block: {
      get() {
        return this.$store.state.block;
      },
      set(v) {
        this.$store.dispatch("changeBlock", v);
      },
    },

    getCityLayer() {
      return this.cname;
    },
    currentArea: {
      get() {
        return this.$store.state.currentArea;
      },
    },
  },

  created() {
    this.currentMapLayer = this.vectorMapLayer;
    this.currentMapLayer.type = "vector";
  },

  methods: {
    toggleMapType(type) {
      if (type === "vector") {
        this.currentMapLayer = this.vectorMapLayer;
        this.currentMapLayer.type = "vector";
      } else if (type === "satellite") {
        this.currentMapLayer = this.satelliteMapLayer;
        this.currentMapLayer.type = "satellite";
      }
    },

    icon(num) {
      if (num == null || num == "") {
        return icon({
          iconUrl: require("@/assets/opcity.png"),
          iconSize: [32, 37],
          iconAnchor: [16, 37],
        });
      } else {
        return icon({
          iconUrl: require("@/assets/type" + num + ".png"),
          iconSize: [32, 37],
          iconAnchor: [16, 37],
        });
      }
    },

    handleImg(data) {
      const res = data.map((item) => {
        item.imgurl = require("@/assets/icon/icontype" + item.type + ".png");
        return item;
      });
      return res;
    },

    //获取点数据
    getPointData() {
      const params = {
        city_plate_id: this.block,
        type: this.cate,
      };

      pointData(params).then((res) => {
        this.pointsList = this.handleImg(res.data);
      });
    },

    bd_convert_gd(coordinate) {
      var bd_lng = coordinate[0];
      var bd_lat = coordinate[1];
      var pi = (3.14159265358979324 * 3000.0) / 180.0;
      var x = bd_lng - 0.0065;
      var y = bd_lat - 0.006;
      var z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * pi);
      var theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * pi);
      var gd_lng = z * Math.cos(theta);
      var gd_lat = z * Math.sin(theta);
      return [gd_lng, gd_lat];
    },
    getCityBoundaryData() {
      const params = {
        city_plate_id: this.currentArea,
        type: 10,
      };

      if (this.currentArea) {
        polygonData(params).then((res) => {
          const polygondata = res.data;
          polygondata.map((item) => {
            this.handlerData(item.set);
          });
        });
      }
    },
    // 处理数据
    handlerData(data) {
      this.polygonArr = [];
      const res = JSON.parse(data);
      this.center = L.latLng(
        res[0].centerpoint.split(",")[1],
        res[0].centerpoint.split(",")[0]
      );
      res.map((item) => {
        const polygon = item.multipoint.split(";");
        const polygonArrs = [];

        polygon.map((item) => {
          const arr = item.split(",");

          let newarr;
          // if (isgaode) {
          //   newarr = this.bd_convert_gd([arr[0], arr[1]]);
          // } else {
            newarr = arr;
          // }

          polygonArrs.push([newarr[1], newarr[0]]);
        });
        const polygonObj = {
          color: "#000000",
          fillColor: "#000000",
          fillOpacity: 0.6,

          latlngs: polygonArrs,
        };
        this.polygonArr.push(polygonObj);
        console.log("this.polygonArr", this.polygonArr);
      });
    },
  },

  data() {
    return {
      //矢量地图对象
      vectorMapLayer: vectorMapLayer,
      //卫星地图数据对象
      satelliteMapLayer: satelliteMapLayer,
      //当前地图图层
      currentMapLayer: {
        url: "",
        texturl: "",
        attribution: "",
        type: "vector",
      },

      pointsList: [],
      polygonArr: [],
      zoom: 14,
      crs: L.CRS.EPSG3857, // 使用的是EPSG:3857坐标系
      center: L.latLng(),
      //   url: 'https://webrd04.is.autonavi.com/appmaptile?lang=zh_cn&size=1&scale=1&style=7&x={x}&y={y}&z={z}',
      url: leafletmapurl,
      attribution: "",
      marker: L.latLng(18.212516, 109.482965),
      text: "this is a marker",
      showParagraph: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.plugin {
  height: 0px;
  width: 120px;
  display: flex;
  background: #fff;
  position: relative;
  float: left;
  top: 20px;
  z-index: 1000;
  left: 60px;
  .pbtn {
    width: 60px;
    height: 20px;
    color: #000;
    font-weight: bold;
    line-height: 20px;
    background: #fff;
    border: solid 1px #fff;
    cursor: pointer;
  }
  .activebtn {
    color: #1384cf;
    border: solid 1px #1384cf;
  }
}
/* 隐藏国企以及版权标识 */
::v-deep .leaflet-control-attribution,
.leaflet-control {
  display: none !important;
}

.maincon {
  position: relative;
  width: 100%;
  height: calc(100vh - 220px);
}

.tooltipInnerBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .icon {
    height: 30px;
    width: 30px;
  }
  .title {
    font-size: 16px;
    margin-left: 5px;
  }
}
</style>
