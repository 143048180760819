<template>
  <div class="cityLocation">
    <div class="areaMap">
      <leaflet-block-ye :cate="1" :polygondata="polygondata"></leaflet-block-ye>
    </div>
  </div>
</template>
<script>
import leafletBlockYe from '@/components/leaflet/leafletBlockYe.vue'

import {   polygonData } from "@/api/cityresearch"
import {isgaode} from "@/settings.js"

export default {
  components: { leafletBlockYe },
  data(){
    return {
      polygondata: []
    }
  },

  computed: {

    //当前选择的id
    block: {
      get() {
        return this.$store.state.block
      },
      set(v) {
        this.$store.commit('changeBlock', v)
      }
    }
  },
  watch: {
    block(newv) {
      this.getPolygonData()
    },

  },
  mounted() {

    this.getPolygonData()
  },
  methods: {
    getPolygonData() {
      const params = {
        city_plate_id: this.block,
        type: 10,
        is_gaode: isgaode
      }

      polygonData(params).then(res => {
        const polygondata = res.data
        this.polygondata = polygondata
      })
    }
  }
}

</script>
<style lang="scss">
.cityLocation {
  width: 100%;
  height: calc(100vh - 100px);
  color: #333;
}

.areaMap {
  width: 98%;
  margin: 1%;
  height: calc(100vh - 284px);
  background: #ddd;
}

.swipercontainer {
  position: relative;
  bottom: 0;
  right: 0;
  left: 0;
  top: -100%;
  z-index: 10;
}
</style>